#map {
    width: 100%;
    height: 430px;
    border: 1px solid #ccc;
    // margin-bottom: 10px;
    background-color: #aad3df;
    // background-image: url('../images/stars.png');
    // background-position: center;
    // background-size: 100vh;
    // background-repeat: repeat;
}

.custom-marker {
    width: 10px;
    background: #000;
    border: 1px solid #000;
    border-radius: 50%;
    // box-shadow: 0px 0px 10px 0px #000;
  }

.custom-marker-shadow {
    width: 10px;
    background: #000;
    border: 1px solid #000;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px #000;
  }

.ion-flag-large {
  font-size: 25px;
}

.ion-flag-blue {
  color: black
}

.ion-flag-gold {
  color: goldenrod
}

.ion-flag-green {
  color: green
}